<template>
  <div class="ap-alert-message">
    <v-bottom-sheet
      hide-overlay
      inset
      v-model="open"
    >
      <v-alert
        class="full-alert"
        :type="transform.type"
        dismissible
        v-model="open"
        transition="scale-transition"
      >
        {{ transform.text }}
      </v-alert>

    </v-bottom-sheet>
  </div>
</template>

<style scoped>
  .full-alert {
    margin: 0px;
  }
</style>


<script>
import { mapGetters } from 'vuex';
import ResponseTransform from '@/utils/ResponseTransform';

export default {
  name: 'TheAlertMessage',
  data: () => ({
    transform: new ResponseTransform(),
    open: false,
    timerid: null,
  }),
  computed: {
    ...mapGetters('alertMessage', [
      'getApiResponse',
      'getAlertMessage',
    ]),
  },
  watch: {
    getApiResponse(resp) {
      this.transform.setComponent(resp.component);
      if (resp.status === 200 || resp.status === 201) {
        this.transform.saved(resp.status);
      } else if (resp.status === 204) {
        this.transform.deleted(resp.status);
      } else if (resp.status === 409) {
        this.transform.error(resp.data.errors[0]);
      } else {
        this.transform.error('Unknown Error Occurred.');
      }
      this.toggle();
    },
    getAlertMessage(msgConfig) {
      switch (msgConfig.type) {
        case 'success':
          this.transform.success(msgConfig.message);
          break;
        case 'error':
          this.transform.error(msgConfig.message);
          break;
        case 'info':
          this.transform.info(msgConfig.message);
          break;
        case 'unknown':
          this.transform.error('Unknown Error Occurred.');
          break;
        default:
          break;
      }
      this.toggle();
    },
  },
  methods: {
    toggle() {
      this.open = true;
      if (this.timerid) {
        clearTimeout(this.timerid);
      }
      this.timerid = setTimeout(() => {
        this.open = false;
      }, 4000);
    },
  },
};
</script>
