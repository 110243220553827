class ResponseTransform {
  constructor(componentName) {
    this.componentName = componentName || '';
    this.type = 'info';
    this.text = '';
  }

  saved(status) {
    switch (status) {
      case 200:
        this.success(this.componentName.concat(' Saved'));
        break;

      case 201:
        this.info(this.componentName.concat(' Created'));
        break;

      case 500:
      default:
        this.error('Unknown error');
        break;
    }
  }

  deleted(status) {
    if (status === 204) {
      this.success(this.componentName.concat(' Deleted'));
    } else {
      this.error('Unknown error');
    }
  }

  error(message) {
    this.type = 'error';
    this.text = message;
  }

  info(message) {
    this.type = 'info';
    this.text = message;
  }

  success(message) {
    this.type = 'success';
    this.text = message;
  }

  setComponent(componentName) {
    this.componentName = componentName;
  }
}

export default ResponseTransform;
